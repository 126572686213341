.mg-filter {
  overflow-wrap: anywhere;
}

.mg-filter-checkbox-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mg-switch-and-slider {
  display: flex;
  align-items: center;
}
.mg-switch-and-slider > *:last-child {
  flex-grow: 2;
}
