@media (min-width: 846px) {
    .mg-contigs-list {
        grid-template-columns: 25% 75%;
    }
}

.contig-igv-container {
    min-height: 240px;
}

.mg-contig-filter.vf-form__legend {
    font-size: 1.2rem;
}

details summary {
    cursor: pointer;
}

details summary > * {
    display: inline;
}